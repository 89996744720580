import React, { useState } from 'react';
import Navbar from './Navbar'
import Footer from './Footer'
import { XIcon } from '@heroicons/react/outline';
import Link from 'next/link';

export default function Layout({ children }) {
  const [showPolicy, setShowPolicy] = useState(true);
  return (
    <>
      <Navbar />
      <div>
        {children}
        {/* Privacy policy banner start */}
        {showPolicy &&
          <div className='fixed bottom-0 w-screen z-10 md:z-0'>
            <div className='drop-shadow-2xl bg-white text-gray-700 text-sm p-2 shadow'>
              <div className='flex max-w-4xl mx-auto mb-0'>
              <div onClick={() => setShowPolicy(false)} className='mt-1 text-xs uppercase cursor-pointer flex items-center'><XIcon className="text-gray-800 hover:text-gray-500 h-4 w-4 mx-2" /></div>
                <div>
                  We use essential cookies to make our site work. We use non-essential cookies to improve user experience and analyze website traffic.
                  By using this site, you agree to our website&apos;s cookie use as described in our
                  <Link href="/cookie-policy" passHref><a className="ml-1 hover:underline text-blue-800 hover:text-blue-900">Cookie Policy</a></Link>.
                </div>
                
              </div>
            </div>
          </div>
        }
        {/* Privacy policy banner end */}
      </div>
      <Footer />
    </>
  )
}