import React, { useEffect } from 'react'
import Layout from '../components/Layout'
import '../styles/globals.css'
import TagManager from 'react-gtm-module';
import Script from 'next/script'
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

function MyApp({ Component, pageProps }) {

  useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-5453FHV' });
  }, []);

  return (

    <GoogleReCaptchaProvider
      reCaptchaKey="6LdB7EMjAAAAAKwoXwSwWSGM1TlNtadZXx6iNid7"
      scriptProps={{
        async: false,
        defer: false,
        appendTo: "head",
        nonce: undefined,
      }}
    >
      {/* <UserProvider> */}
      <Layout>

        {/* google tag manager */}
        <Script id="google-tag-manager" strategy="afterInteractive">
          {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-5453FHV');`}
        </Script>
        {/* <AlertProvider template={AlertTemplate} {...options}> */}
        <Component {...pageProps} />
        {/* Global site tag (gtag.js) - Google Analytics */}
        <Script
          src="https://www.googletagmanager.com/gtag/js?id=G-CC6N1YYHT4"
          strategy="afterInteractive"
        />
        <Script id="google-analytics" strategy="afterInteractive">
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-CC6N1YYHT4');
        `}
        </Script>


        {/* tiktok */}
        <Script id="tiktok-pixel-code">
          {
            `
          !function (w, d, t) {
            w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++
        )ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script");n.type="text/javascript",n.async=!0,n.src=i+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};
          
            ttq.load('CEG9M6RC77UA35R4UJTG');
            ttq.page();
          }(window, document, 'ttq');
  `
          }
        </Script>

        {/* facebook start */}
        <Script id="facebook-pixel-code">
          {`
                !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window,document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '1275104163285858'); 
      fbq('track', 'PageView');
`}
        </Script>
        <noscript>
          <img height="1" width="1"
            src="https://www.facebook.com/tr?id=1275104163285858&ev=PageView
&noscript=1"/>
        </noscript>
        {/* facebook end */}

        {/* </AlertProvider> */}
      </Layout>
      {/* </UserProvider> */}
    </GoogleReCaptchaProvider>
  )
}

export default MyApp