import React, { useState, useEffect } from 'react'
import Link from 'next/link'
import Image from 'next/image'
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import {
  ScaleIcon,
  MailIcon,
  MenuIcon,
  XIcon,
  BookOpenIcon,
  SearchIcon,
  CurrencyDollarIcon,
} from '@heroicons/react/outline'
import { ChevronDownIcon, BadgeCheckIcon } from '@heroicons/react/solid'
import { cloudfront } from './utils'
import { supabase } from '../utils/supabaseClient'

const LawsuitArray = [
  // {
  //   name: 'Paraquat Lawsuits',
  //   description: 'Paraquat is a toxic herbicide that causes a 250% increase in...',
  //   href: '/lawsuit/paraquat',
  //   icon: ScaleIcon,
  //   active: true,
  // },
  {
    name: 'Hair Relaxer Lawsuits',
    description: 'Hair relaxers have been marketed to African American women for decades...',
    href: '/lawsuit/hair-relaxer',
    icon: ScaleIcon,
    active: true,
  },
  {
    name: 'Mormon Sexual Abuse Lawsuits',
    description: 'Sexual abuse in religious communities is a serious issue...',
    href: '/lawsuit/mormon-sexual-abuse',
    icon: ScaleIcon,
    active: true,
  },
  // {
  //   name: 'Tylenol Lawsuits',
  //   description: 'Tylenol, chemically known as acetaminophen, first raised concern in 2008...',
  //   href: '/lawsuit/acetaminophen',
  //   icon: ScaleIcon,
  //   active: true,
  // },
  // {
  //   name: 'Roundup Lawsuits',
  //   description: 'Roundup is sold in neighborhood hardware and home improvement stores and is a favored weed killer...',
  //   href: '/lawsuit/weedkiller',
  //   icon: ScaleIcon,
  //   active: true,
  // },
  // {
  //   name: 'Talcum Powder Lawsuits',
  //   description: 'Multiple studies done over the course of the last 65 years have found that the mineral talc...',
  //   href: '/lawsuit/talcum-powder',
  //   icon: ScaleIcon,
  //   active: true,
  // },
  // {
  //   name: 'Camp Lejeune Lawsuits',
  //   description: 'Since August 2, 2022, the Camp Lejeune Justice Act allows those exposed to contaminated water at...',
  //   href: '/lawsuit/camp-lejeune',
  //   icon: ScaleIcon,
  //   active: true,
  // },
]

const CaseReviewArray = [
  // {
  //   name: 'Paraquat Settlement',
  //   href: '/case-review/paraquat',
  //   icon: ScaleIcon,
  //   active: true,
  // },
  {
    name: 'Hair Relaxer Settlement',
    href: '/case-review/hair-relaxer',
    icon: ScaleIcon,
    active: true,
  },
  {
    name: 'Mormon Sexual Abuse',
    href: '/case-review/mormon-sexual-abuse',
    icon: ScaleIcon,
    active: true,
  },
  {
    name: 'Ozempic',
    href: '/case-review/ozempic',
    icon: ScaleIcon,
    active: true,
  },
  // {
  //   name: 'Tylenol Settlement',
  //   href: '/case-review/acetaminophen',
  //   icon: ScaleIcon,
  //   active: true,
  // },
  // {
  //   name: 'Roundup Settlement',
  //   href: '/case-review/weedkiller',
  //   icon: ScaleIcon,
  //   active: true,
  // },
  // {
  //   name: 'Talcum Powder Settlement',
  //   href: '/case-review/talcum-powder',
  //   icon: ScaleIcon,
  //   active: true,
  // },
  // {
  //   name: 'Camp Lejeune Settlement',
  //   href: '/case-review/camp-lejeune',
  //   icon: ScaleIcon,
  //   active: true,
  // },
]

const callsToAction = [
  { name: 'All Lawsuits', href: '/lawsuits', icon: ScaleIcon },
  { name: 'Contact', href: 'mailto:info@thelegalcanary.com', icon: MailIcon },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}




export default function Navbar() {
  const [loggedOut, setLoggedOut] = useState(true)

  useEffect(() => supabase.auth.user() && setLoggedOut(false), [])

  return (
    <div>
      <div className="text-center p-2 bg-gray-900"></div>
      <Popover className="nav bg-sky-800 relative">
        <div className="max-w-7xl mx-auto px-4 py-3 sm:px-6">
          <div className="flex justify-between items-center md:justify-start md:space-x-10">
            <Link href="/" passHref>
              <a className="flex justify-start lg:w-0 lg:flex-1">
                  <Image
                    src={`/legalcanary-logo.png`}
                    height='45'
                    width='150'
                    objectFit='contain'
                    alt="thelegalcanary.com"
                  />
              </a>
            </Link>

            <div className="-mr-2 -my-2 md:hidden">
              <Popover.Button className="bg-transparent rounded-md p-2 inline-flex items-center justify-center text-gray-200 hover:text-gray-100 hover:bg-sky-600 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-sky-500">
                <span className="sr-only">Open menu</span>
                <MenuIcon className="h-6 w-6" aria-hidden="true" />
              </Popover.Button>
            </div>
            <Popover.Group as="nav" className="hidden md:flex space-x-10">
            <Popover className="relative">
                {({ open }) => (
                  <>
                    <Popover.Button
                      className={classNames(
                        'text-white group bg-transparent rounded-md inline-flex items-center text-base font-medium hover:text-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500'
                      )}
                    >
                      <span className='flex items-center'>
                        <CurrencyDollarIcon className='h-5 w-5' />
                      <span className='ml-2 hidden flex-1 lg:inline'>Settlements</span>
                      </span>
                      <ChevronDownIcon
                        className={classNames(
                          'text-white ml-2 h-5 w-5 group-hover:text-gray-300'
                        )}
                        aria-hidden="true"
                      />
                    </Popover.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel focus className="absolute z-30 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
                        <div className="rounded-lg shadow-lg ring-1 ring-white ring-opacity-5 overflow-hidden">
                          <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                            {CaseReviewArray.map((item) => (

                              // <Link  passHref>
                              <a key={item.name} href={item.href}
                                className={`group -m-3 p-3 flex items-start rounded-lg hover:bg-sky-100`}
                              >
                                <CurrencyDollarIcon className={`flex-shrink-0 h-6 w-6 group-hover:text-sky-800 text-sky-600`} aria-hidden="true" />
                                <div className="ml-4">
                                  <span className="ml-3 text-base font-medium group-hover:text-gray-900 text-gray-800">{item.name}</span><br />
                                </div>
                              </a>
                              // </Link>

                            ))}
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
              <Popover className="relative">
                {({ open }) => (
                  <>
                    <Popover.Button
                      className={classNames(
                        'text-white group bg-transparent rounded-md inline-flex items-center text-base font-medium hover:text-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500'
                      )}
                    >
                      <span className='flex items-center'>
                        <ScaleIcon className='h-5 w-5' />
                        <span className='ml-2 hidden flex-1 lg:inline'>Lawsuits</span>
                      </span>
                      <ChevronDownIcon
                        className={classNames(
                          'text-white ml-2 h-5 w-5 group-hover:text-gray-300'
                        )}
                        aria-hidden="true"
                      />
                    </Popover.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel focus className="absolute z-30 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
                        <div className="rounded-lg shadow-lg ring-1 ring-white ring-opacity-5 overflow-hidden">
                          <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                            {LawsuitArray.map((item) => (

                              // <Link  passHref>
                              <a key={item.name} href={item.href}
                                className={`group -m-3 p-3 flex items-start rounded-lg hover:bg-sky-100`}
                              >
                                <item.icon className={`flex-shrink-0 h-6 w-6 group-hover:text-sky-800 text-sky-300`} aria-hidden="true" />
                                <div className="ml-4">
                                  <span className="ml-3 text-base font-medium group-hover:text-gray-900 text-gray-800">{item.name}</span><br />
                                  <div className={"ml-3 mt-1 text-sm group-hover:text-gray-600 text-gray-500"}>{item.description}</div>
                                </div>
                              </a>
                              // </Link>

                            ))}
                          </div>

                          <div className="px-5 py-5 bg-gray-100 space-y-6 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">
                            {callsToAction.map((item) => (
                              <div key={item.name} className="flow-root group">

                                <a href={item.href}
                                  className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-700 hover:bg-gray-200 hover:text-black"
                                >
                                  <item.icon className="flex-shrink-0 h-6 w-6 text-gray-700 group-hover:text-gray-800" aria-hidden="true" />
                                  <span className="ml-3">{item.name}</span>
                                </a>
                              </div>
                            ))}
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
              
              <Link href="/about" passHref>
                <a className="text-base font-medium text-white hover:text-gray-300">
                  <span className='flex items-center'>
                    <BadgeCheckIcon className='h-5 w-5' />
                    <span className='ml-2 hidden flex-1 lg:inline'>About</span>
                  </span>
                </a>
              </Link>
              <Link href="/blog" passHref>
                <a className="text-base font-medium text-white hover:text-gray-300">
                  <span className='flex items-center'>
                    <BookOpenIcon className='h-5 w-5' />
                    <span className='ml-2 hidden flex-1 lg:inline'>Blog</span>
                  </span>
                </a>
              </Link>


              {/* <Popover className="relative">
              {({ open }) => (
                <>
                  <Popover.Button
                    className={classNames(
                      open ? 'text-gray-500' : 'text-gray-600',
                      'group bg-transparent rounded-md inline-flex items-center text-base font-medium hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500'
                    )}
                  >
                    <span className='flex items-center'>
                      <AcademicCapIcon className='h-5 w-5' />
                      <span className='ml-2 hidden flex-1 lg:inline'>Learn</span>
                    </span>
                    <ChevronDownIcon
                      className={classNames(
                        open ? 'text-gray-600' : 'text-gray-700',
                        'ml-2 h-5 w-5 group-hover:text-gray-500'
                      )}
                      aria-hidden="true"
                    />
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel focus className="absolute z-40 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-md sm:px-0">
                      <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                        <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                          {LearnArray.map((item) => (
                            item.active ?
                              <Link href={item.href} key={item.name} passHref>
                                <a
                                  className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-200"
                                >
                                  <item.icon className={`flex-shrink-0 h-6 w-6 text-${item.color}-600`} aria-hidden="true" />
                                  <div className="ml-4">
                                    <span className="text-base font-medium text-gray-900">{item.name}</span><br />
                                    <span className="mt-1 text-sm text-gray-500">{item.description}</span>
                                  </div>
                                </a>
                              </Link>
                              :
                              <div
                                key={item.name}
                                className="-m-3 p-3 flex items-start rounded-lg"
                              >
                                <item.icon className="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" />
                                <div className="ml-4">
                                  <div className="flex">
                                    <span className="text-base font-medium text-gray-400">{item.name}</span>
                                    <div className="bg-gray-100 rounded-full mx-1 px-2">
                                      <span className='text-gray-400 text-xs'>COMING SOON</span>
                                    </div>
                                  </div>
                                  <span className="mt-1 text-sm text-gray-400">{item.description}</span>
                                </div>
                              </div>


                          ))}
                        </div>
                        <div className="px-5 py-5 bg-gray-50 sm:px-8 sm:py-8">
                          <div>
                            <h3 className="text-sm tracking-wide font-medium text-gray-500 uppercase">Recent Posts</h3>
                            <ul role="list" className="mt-4 space-y-4">
                              {recentPosts.map((post) => (
                                <li key={post.id} className="text-base truncate">
                                  <Link href={post.href} passHref>
                                    <a className="font-medium text-gray-500 hover:text-gray-700">
                                      {post.name}
                                    </a>
                                  </Link>
                                  
                                </li>
                              ))}
                            </ul>
                          </div>
                          <div className="mt-5 text-sm">
                            <Link href="/blog" passHref>
                              <a className="font-medium text-sky-600 hover:text-sky-800">
                                {' '}
                                View all posts <span aria-hidden="true">&rarr;</span>
                              </a>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover> */}
            </Popover.Group>



            <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
              <Link href={loggedOut ? "/lawsuit/eligibility-quiz" : "/admin"} passHref>
                <a className="transition 
                        ease-in-out 
                        delay-150 
                        text-white
                        hover:-translate-y-1 
                        hover:drop-shadow-xl
                        bg-sky-700
                        hover:bg-sky-700
                        duration-300 
                        px-4 xl:px-6 py-4
                        drop-shadow-lg 
                        tracking-widest
                        rounded-sm 
                        text-sm
                        flex 
                        ml-5
                        font-extrabold 
                        uppercase 
                        items-center 
                        justify-center">
                  {loggedOut ? "Eligibility Quiz" : "Admin"}
                </a>
              </Link>
            </div>


            {/* Profile dropdown */}
            {/* {user && !isLoading &&
              <>
                <span className='text-sm font-semibold text-gray-700'>Hello, <span className='italic'>{user.username}</span></span>
                {user?.superuser &&
                  <>
                    <a>
                      <BadgeCheckIcon className='mx-1 h-4 w-4 text-indigo-400' />
                    </a>
                  </>
                }
                <Menu as="div" className="ml-3 relative">
                  <div>
                    <Menu.Button className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                      <span className="sr-only">Open user menu</span>
                      <div className="h-10 w-10 rounded-full">
                        {user?.user_metadata.picture ?
                          <Image
                            className="h-8 w-8 rounded-full"
                            src={`${user.user_metadata.picture}`}
                            height={50}
                            width={50}
                          />
                          :

                          <UserIcon className='text-white p-1' />

                        }
                      </div>
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="origin-top-right z-50 absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {user?.superuser &&
                        <Menu.Item>
                          {({ active }) => (
                            <Link href="/admin">
                              <a className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                              >
                                Admin
                              </a>
                            </Link>
                          )}
                        </Menu.Item>
                      }
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                          >
                            Profile
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <Link href="/settings">
                            <a className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                            >
                              Settings
                            </a>
                          </Link>

                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <Link href="/logout">
                            <a
                              className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                            >
                              Logout
                            </a>
                          </Link>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </>
            } */}

          </div>
        </div>
        {/* mobile */}
        <Transition
          as={Fragment}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel focus className="absolute top-0 z-50 inset-x-0 p-2 transition transform origin-top-right md:hidden">
            <div className="rounded-lg shadow-lg ring-1 ring-white ring-opacity-5 bg-sky-900 divide-y-2 divide-sky-700">
              <div className="pt-5 pb-6 px-5">
                <div className="flex items-center justify-between">
                  <div>
                    <Image
                      src={`/legalcanary-logo.png`}
                      height='45'
                      width='150'
                      objectFit='contain'
                      alt='thelegalcanary.com'
                    />
                  </div>
                  <div className="-mr-2">
                    <Popover.Button className="bg-gray-900 rounded-md p-2 inline-flex items-center justify-center text-gray-200 hover:text-gray-100 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-sky-500">
                      <span className="sr-only">Close menu</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>

                <div className="mt-6">
                  <nav className="grid gap-y-8">
                    {CaseReviewArray.map((item) => (
                      item.active &&
                      <a
                        href={item.href} key={item.name}
                        className="group -m-3 p-3 flex items-center rounded-md hover:bg-white"
                      >
                        <item.icon className={`flex-shrink-0 h-6 w-6 group-hover:text-sky-800 text-sky-300`} aria-hidden="true" />
                        <span className="ml-3 text-base font-medium group-hover:text-gray-800 text-white">{item.name}</span>
                      </a>
                    ))}
                  </nav>
                </div>
                {!supabase.auth.user() &&
                  <div className='mt-10'>
                    <Link href="/lawsuit/eligibility-quiz" passHref>
                      <a className="transition 
                        ease-in-out 
                        delay-150 
                        text-white
                        hover:-translate-y-1 
                        hover:drop-shadow-xl
                        bg-green-600
                        hover:bg-green-600
                        duration-300 
                        px-6 py-4
                        drop-shadow-lg 
                        tracking-widest
                        rounded-sm 
                        flex 
                        font-extrabold 
                        uppercase 
                        items-center 
                        justify-center">
                        Eligibility Quiz
                      </a>
                    </Link>
                  </div>
                }
                {supabase.auth.user() &&
                  <div className='mt-10 flex'>
                    <Link href="/logout" passHref>
                      <a className="
                  text-white
                  hover:text-gray-200
                  text-baseF
                  hover:underline
                        flex 
                        ml-5
                        font-extrabold 
                        uppercase 
                        items-center 
                        justify-center">
                        Logout
                      </a>
                    </Link>
                    <Link href="/admin" passHref>
                      <a className="transition 
                        ease-in-out 
                        delay-150 
                        text-white
                        hover:-translate-y-1 
                        hover:drop-shadow-xl
                        bg-green-600
                        hover:bg-green-600
                        duration-300 
                        px-6 py-4
                        drop-shadow-lg 
                        tracking-widest
                        rounded-sm 
                        flex 
                        ml-5
                        font-extrabold 
                        uppercase 
                        items-center 
                        justify-center">
                        Admin
                      </a>
                    </Link>
                  </div>
                }
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </div>
  )
}
