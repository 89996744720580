import Image from 'next/image';
import Link from 'next/link'
import React from 'react';
import { cloudfront } from './utils';
import { useRouter } from 'next/router';
import { supabase } from '../utils/supabaseClient';

export default function Footer() {
  const { asPath, pathname } = useRouter()

  return (
    <footer className="text-center lg:text-left bg-sky-900 text-white">
      <div className="p-6 bg-sky-800">

      </div>
      <div className="mx-6 py-10 text-center md:text-left">
        <div className="flex flex-col md:grid md:grid-cols-2 lg:grid-cols-4 md:gap-1">

          <div className="col-span-12 md:col-span-1 mb-10 md:mb-0">
            <h6 className="uppercase font-semibold mb-4 flex justify-center md:justify-start">
              Lawsuits
            </h6>
            <p className="mb-4">
              <Link href="/lawsuit/mormon-sexual-abuse" passHref>
                <a className="text-white hover:underline">Mormon Sexual Abuse Lawsuit</a>
              </Link>

            </p>
            <p className="mb-4">
              <Link href="/lawsuit/hair-relaxer" passHref>
                <a className="text-white hover:underline">Hair Relaxer Lawsuit</a>
              </Link>

            </p>
            <p className="mb-4">
              <Link href="/lawsuit/ozempic" passHref>
                <a className="text-white hover:underline">Ozempic Lawsuit</a>
              </Link>

            </p>
            <p className="mb-4">
              <Link href="/lawsuit/ride-share" passHref>
                <a className="text-white hover:underline">Ride Share Lawsuit</a>
              </Link>

            </p>
            <p className="mb-4">
              <Link href="/lawsuit/talcum-powder" passHref>
                <a className="text-white hover:underline">Talcum Powder Lawsuit</a>
              </Link>

            </p>
            {/* <p className="mb-4">
              <Link href="/lawsuit/acetaminophen" passHref>
                <a className="text-white hover:underline">Tylenol Lawsuit</a>
              </Link>

            </p> */}
            {/* <p className="mb-4">
              <Link href="/lawsuit/camp-lejeune" passHref>
                <a className="text-white hover:underline">Camp Lejeune Lawsuit</a>
              </Link>

            </p> */}
          </div>
          <div className="col-span-12 md:col-span-1 mb-10 md:mb-0">
            <h6 className="uppercase font-semibold mb-4 flex justify-center md:justify-start">
              Information
            </h6>
            <p className="mb-4">
              <Link href="/partners" passHref>
                <a className="text-white hover:underline">Our Partners</a>
              </Link>
            </p>
            <p className="mb-4">
              <Link href="mailto:info@thelegalcanary.com" passHref>
                <a className="text-white hover:underline">Contact Us</a>
              </Link>
            </p>

            {supabase.auth.user() ?
              <div>
                <p className="mb-4">
                  <Link href="/admin" passHref>
                    <a className="text-white hover:underline">Admin</a>
                  </Link>
                </p>
                <p className="mb-4">
                  <Link href="/logout" passHref>
                    <a className="text-white hover:underline">Logout</a>
                  </Link>
                </p>
              </div>
              :
              <p className="mb-4">
                <Link href="/login" passHref>
                  <a className="text-white hover:underline">Login</a>
                </Link>
              </p>
            }
          </div>
          <div className="col-span-2 order-last">
            <h6 className="
            uppercase
            font-semibold
            mb-4
            flex
            items-center
            justify-center
            md:justify-start
          ">
              {/* <Image 
            src= {`${cloudfront}/images/logo_dark.png`}
            height='50'
            width='150'
            objectFit='contain'
          /> */}
            </h6>
            <div className='flex justify-center md:justify-start mb-5 -mt-5'>
              <img src="/legalcanary-logo.png" alt="thelegalcanary.com" className='h-8' />
            </div>
            <div className="text-xs leading-tight lg:max-w-lg mx-auto lg:mx-0 text-center md:text-left">
              This website and its content may be deemed attorney advertising.  TheLegalCanary.com partners with law firms within United States.
              The information on this website is not medical advice and is not a substitute for professional medical advice, diagnosis or treatment.
              The information on this website does not constitute as legal advice and is not a substitute for professional legal advice.
              Please seek the advice of medical and legal professionals before making health care and legal decisions.
              No attorney-client relationship is formed by your use or communication through this website.
              Choosing a lawyer is an important decision and should not be based solely on advertisements.
              Craftwise LLC and TheLegalCanary.com are not law firms.


            </div>
            <div className='flex items-baseline text-xs mt-2 mb-10 text-white justify-center md:justify-start'>
              <Link href="/terms-conditions" passHref><a className='hover:underline text-white'>Terms &amp; Conditions</a></Link>
              <Link href="/privacy-policy" passHref><a className='ml-2 hover:underline text-white'>Privacy Policy</a></Link>
              <Link href="/cookie-policy" passHref><a className='ml-2 hover:underline text-white'>Cookie Policy</a></Link>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center p-2 bg-gray-900">
        <div className="text-white font-normal text-sm">© 2022 TheLegalCanary.com. All Rights Reserved.</div>
      </div>
    </footer>
  );
}
