import { supabase } from "../utils/supabaseClient";

export const cloudfront = 'https://d367gcy56r7svf.cloudfront.net'

export const StringToSlug = (str) => {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to = "aaaaeeeeiiiioooouuuunc------";
    for (var i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes

    return str;
}

export const convertDateMonthDayYear = (published_at) => {
    let options = { year: 'numeric', month: 'short', day: 'numeric' }
    var date = new Date(published_at);
    var dateStr = date.toLocaleString('en-US', options) // "Feb 25, 2022"
    return published_at ? dateStr : null
}

export const convertDateMonthDayYearTime = (published_at) => {
    var date = new Date(published_at);
    var dateStr = date.toLocaleString() // "4/11/2022, 2:59:58 PM"
    return published_at ? dateStr : null
}

export const convertYearMonthDay = (published_at) => {
    var date = new Date(published_at);
    var dateStr = date.toISOString().slice(0, 10) // "yyyy-mm-dd"
    return published_at ? dateStr : null
}

export async function orderFetcher(table, query, order, direction) {
    const { data, error } = await supabase
        .from(table)
        .select(query)
        .order(order, { ascending: direction })
    error && console.log(error)
    return data
};